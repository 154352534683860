<template>
  <Collection
    v-if="collection && collection.products"
    title="Shop"
    :subtitle="collection.title[0].text"
    :description="collection.description"
    :products="collection.products"
    :to="`/shop/collections/${collection._meta && collection._meta.uid}`"
  />
</template>

<script>
  import {
    fetchCollectionProductsByUIDs
  } from "@/prismic/queries";

  import Collection from "@/components/shop/Collection";

  export default {
    name: 'CollectionSlice',

    components: {
      Collection
    },

    data() {
      return {
        collection: null
      }
    },

    props: {
      slice: {
        type: Object,
        required: true,
        default() {
          return {}
        }
      }
    },

    async fetch() {
      const collectionUid = this.slice?.primary?.collection?.uid;

      if (!collectionUid) return null;

      try {
        const { data } = await this.$apolloProvider.defaultClient.query({
          query: fetchCollectionProductsByUIDs,
          variables: {
            uids: [collectionUid]
          }
		    });

        const response = data.allCollections.edges;

        if(response.length) {
          this.collection = response[0].node;
        }
      } catch(err) {
        console.log(err);
      }
    }
  }
</script>
